<template>
  <div class="row">
    <div class="form-group col-md-12 text-right">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        data-toggle="modal"
        data-target="#modal_tarifa"
        @click="getDatosModal()"
        v-if="$store.getters.can('tep.contratosNacionales.edit')"
      >
        <i class="fas fa-plus"></i>
      </button>
    </div>
    <table
      class="
        table table-bordered table-striped table-hover table-sm
      "
      v-if="$parent.form"
    >
      <thead>
        <tr>
          <th class="text-center">
            Tarifa
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="filtros.n_tarifa"
            />
          </th>

          <th class="text-center">
            Tipo Vehículo
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="filtros.tipo_vh"
            />
          </th>
          <th class="text-center">
            Tipo Tarifa
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="filtros.tipo_tarifa"
            />
          </th>
          <th class="text-center">
            Ruta
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="filtros.ruta"
            />
          </th>
          <th class="text-center">Detalle Tarifa</th>
          <th class="text-center">Valor</th>
          <th class="text-center">Fecha Ini</th>
          <th class="text-center">Fecha Fin</th>
          <th class="text-center" style="width: 120px">
            Acción
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tarifa in filteredRowsDetail" :key="tarifa.id">
          <template v-if="tarifa.det_tarifas.length > 0">
            <td>
              {{
                tarifa.det_tarifas[0].numero_tarifa
                  ? `(${tarifa.det_tarifas[0].numero_tarifa})`
                  : ""
              }}
              {{ tarifa.det_tarifas[0].nombre_tarifa }}
            </td>

            <td class="text-wrap">{{ tarifa.tipo_vehiculo.nombre }}</td>
            <td class="text-wrap">{{ tarifa.det_tarifas[0].nTipoTarifa }}</td>
            <td :class="!tarifa.tep_ruta ? 'text-muted' : ''">
              {{ tarifa.tep_ruta ? tarifa.tep_ruta.nombre : "Sin ruta" }}
            </td>
            <td>
              <div v-if="tarifa.det_tarifas[0].tipo_tarifa == 1">
                <small>
                  Km Mínimos:
                  <strong>{{ tarifa.det_tarifas[0].km_min }}</strong>
                </small>
              </div>
              <div v-else>
                <div>
                  <small>
                    Km Inicial:
                    <strong>
                      {{ tarifa.det_tarifas[0].rango_km_ini }}
                    </strong>
                  </small>
                </div>
                <div>
                  <small>
                    Km Final:
                    <strong>
                      {{ tarifa.det_tarifas[0].rango_km_fin }}
                    </strong>
                  </small>
                </div>
              </div>
            </td>
            <td>
              <div v-if="tarifa.det_tarifas[0].tipo_tarifa == 1">
                <div>
                  <small>
                    Km Pavimentado:
                    <strong>
                      {{ tarifa.det_tarifas[0].val_km_pavimentados }}
                    </strong>
                  </small>
                </div>
                <div>
                  <small>
                    Km Destapado:
                    <strong>
                      {{ tarifa.det_tarifas[0].val_km_destapados }}
                    </strong>
                  </small>
                </div>
              </div>
              <div v-else>
                <small>
                  Tarifa:
                  <strong>
                    {{ tarifa.det_tarifas[0].valor_tarifa }}
                  </strong>
                </small>
              </div>
            </td>
            <td class="text-center">{{ tarifa.det_tarifas[0].fecha_ini }}</td>
            <td class="text-center">{{ tarifa.det_tarifas[0].fecha_fin }}</td>
            <td class="text-right">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-sm bg-info"
                  data-toggle="modal"
                  data-target="#modal_tarifa_historico"
                  @click="getDatosHistorico(tarifa)"
                >
                  <i class="fas fa-history"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  data-toggle="modal"
                  data-target="#modal_tarifa"
                  @click="getDatosModal(tarifa)"
                  v-if="$store.getters.can('tep.contratosNacionales.edit')"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="destroy(tarifa)"
                  v-if="
                    $store.getters.can('tep.contratosNacionales.edit') &&
                      $parent.estado == 1
                  "
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <div class="modal fade" id="modal_tarifa">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Ruta</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="tep_ruta"
                  placeholder="Ruta"
                  label="nombre"
                  :options="$parent.listasForms.rutas"
                  @search="buscarRuta"
                  @input="selectRuta()"
                >
                </v-select>
              </div>
              <div class="form-group col-md-3">
                <label>Fecha Inicio</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_ini"
                  :class="
                    $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Fecha Fin</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_fin"
                  :class="
                    $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Tipo de Vehículo</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_vehiculo_id"
                  :class="
                    $v.form.tipo_vehiculo_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_vehiculo in $parent.listasForms.tipos_vehiculo"
                    :key="tipo_vehiculo.id"
                    :value="tipo_vehiculo.id"
                  >
                    {{ tipo_vehiculo.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Tipo de Tarifa</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_tarifa"
                  @change="resetValues()"
                  :class="
                    $v.form.tipo_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_tarifa in $parent.listasForms.tipos_tarifas"
                    :key="tipo_tarifa.numeracion"
                    :value="tipo_tarifa.numeracion"
                  >
                    {{ tipo_tarifa.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label>Valor Hora Extra</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.valor_horas_extra"
                  :class="
                    $v.form.valor_horas_extra.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 1">
                <label>Km Mínimos</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.km_min"
                  :class="$v.form.km_min.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 1">
                <label>Valor Km Destapado</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.val_km_destapados"
                  :class="
                    $v.form.val_km_destapados.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 1">
                <label>Valor Km Pavimentado</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.val_km_pavimentados"
                  :class="
                    $v.form.val_km_pavimentados.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 2">
                <label>Valor Tarifa</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.valor_tarifa"
                  :class="
                    $v.form.valor_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 2">
                <label>Km Inicial</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.rango_km_ini"
                  :class="
                    $v.form.rango_km_ini.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @blur="validarKm()"
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 2">
                <label>Km Final</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.rango_km_fin"
                  :class="
                    $v.form.rango_km_fin.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @blur="validarKm()"
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 2">
                <label>Valor Km Adicional</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.valor_km_adicional"
                  :class="
                    $v.form.valor_km_adicional.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @blur="validarKm()"
                />
              </div>
              <div class="form-group col-md-3" v-if="form.tipo_tarifa == 2">
                <label>Maximo Horas</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  v-model="form.max_horas"
                />
              </div>
              <div
                class="form-group col-md-3 text-center"
                v-if="form.tipo_tarifa == 2"
              >
                <label class="text-center">Ida y Vuelta</label>
                <input
                  type="checkbox"
                  class="form-control form-control-sm"
                  v-model="form.round_trip"
                />
              </div>
              <div class="form-group col-md-4">
                <label># Tarifa</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="form.numero_tarifa"
                  :class="
                    $v.form.numero_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-12">
                <label>Nombre Tarifa</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="form.nombre_tarifa"
                  :class="
                    $v.form.nombre_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('tep.contratosNacionales.edit') &&
                  !$v.form.$invalid
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <div class="modal fade" id="modal_tarifa_historico">
      <div class="modal-dialog modal-xl" v-if="datos_tarifa_historico">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Histórico Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="position-relative p-3 m-2 bg-light"
              style="font-size: 0.85em"
            >
              <div class="row">
                <div class="col-sm-4">
                  <div class="description-block">
                    <h5
                      class="description-header"
                      :class="datos_tarifa_historico.ruta ? '' : 'text-muted'"
                    >
                      {{
                        datos_tarifa_historico.ruta
                          ? datos_tarifa_historico.ruta
                          : "Sin ruta"
                      }}
                    </h5>
                    <span class="description-text text-secondary">Ruta</span>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.tipo_vehiculo }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Tipo Vehículo</span
                    >
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.nombre_tipo_tarifa }}
                      {{ datos_tarifa_historico.tipo_tarifa }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Tipo Tarifa</span
                    >
                  </div>
                </div>
                <!-- Datos de tarifas pavimentadas/destapadas -->
                <div
                  class="col-sm-2"
                  v-if="datos_tarifa_historico.tipo_tarifa == 1"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.km_min }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Kilómetro Mínimo</span
                    >
                  </div>
                </div>
                <div
                  class="col-sm-3"
                  v-if="datos_tarifa_historico.tipo_tarifa == 1"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.val_km_destapados }}
                    </h5>
                    <span class="description-text text-secondary"
                      >$ Km Destapado</span
                    >
                  </div>
                </div>
                <div
                  class="col-sm-3"
                  v-if="datos_tarifa_historico.tipo_tarifa == 1"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.val_km_pavimentados }}
                    </h5>
                    <span class="description-text text-secondary"
                      >$ Km Pavimentado</span
                    >
                  </div>
                </div>
                <!-- Datos de tarifas costo ruta -->
                <div
                  class="col-sm-2"
                  v-if="datos_tarifa_historico.tipo_tarifa == 2"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.valor_tarifa }}
                    </h5>
                    <span class="description-text text-secondary">Tarifa</span>
                  </div>
                </div>
                <div
                  class="col-sm-2"
                  v-if="datos_tarifa_historico.tipo_tarifa == 2"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.rango_km_ini }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Km Inicial</span
                    >
                  </div>
                </div>
                <div
                  class="col-sm-2"
                  v-if="datos_tarifa_historico.tipo_tarifa == 2"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.rango_km_fin }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Km Final</span
                    >
                  </div>
                </div>
                <div
                  class="col-sm-2"
                  v-if="datos_tarifa_historico.tipo_tarifa == 2"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{
                        datos_tarifa_historico.valor_km_adicional
                          ? datos_tarifa_historico.valor_km_adicional
                          : 0
                      }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Valor Km Adicional</span
                    >
                  </div>
                </div>
                <div
                  class="col-sm-2"
                  v-if="datos_tarifa_historico.valor_horas_extra"
                >
                  <div class="description-block">
                    <h5 class="description-header">
                      ${{ datos_tarifa_historico.valor_horas_extra }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Valor Hora Extra</span
                    >
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.fecha_ini }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Fecha Inicial</span
                    >
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ datos_tarifa_historico.fecha_fin }}
                    </h5>
                    <span class="description-text text-secondary"
                      >Fecha Final</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body table-responsive">
            <table
              class="
                table table-bordered table-striped table-hover
                text-nowrap
                table-sm
              "
            >
              <thead class="thead-dark">
                <tr>
                  <th class="text-center" style="width: 120px">Estado</th>
                  <th class="text-center">Tipo Tarifa</th>
                  <th class="text-center">Tarifa</th>
                  <th class="text-center">Detalle Tarifa</th>
                  <th class="text-center">Valor</th>
                  <th class="text-center">Adicionales</th>
                  <th class="text-center">Fecha Ini</th>
                  <th class="text-center">Fecha Fin</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tarifa in tarifa_historico" :key="tarifa.id">
                  <td class="text-center">
                    <span
                      class="badge"
                      :class="
                        tarifa.estado == 1 ? 'badge-success' : 'badge-danger'
                      "
                      >{{ tarifa.nEstado }}</span
                    >
                  </td>
                  <td>{{ tarifa.nTipoTarifa }}</td>
                  <td>
                    {{
                      tarifa.numero_tarifa ? `(${tarifa.numero_tarifa})` : ""
                    }}
                    {{ tarifa.nombre_tarifa }}
                  </td>
                  <td>
                    <div v-if="tarifa.tipo_tarifa == 1">
                      <small
                        >Km Mínimos: <strong>{{ tarifa.km_min }}</strong></small
                      >
                    </div>
                    <div v-else>
                      <div>
                        <small
                          >Km Inicial:
                          <strong>{{ tarifa.rango_km_ini }}</strong></small
                        >
                      </div>
                      <div>
                        <small
                          >Km Final:
                          <strong>{{ tarifa.rango_km_fin }}</strong></small
                        >
                      </div>
                    </div>
                  </td>
                  <td>
                    <div v-if="tarifa.tipo_tarifa == 1">
                      <div>
                        <small
                          >Km Pavimentado:
                          <strong>{{
                            tarifa.val_km_pavimentados
                          }}</strong></small
                        >
                      </div>
                      <div>
                        <small
                          >Km Destapado:
                          <strong>{{ tarifa.val_km_destapados }}</strong></small
                        >
                      </div>
                    </div>
                    <div v-else>
                      <small
                        >Tarifa:
                        <strong>{{ tarifa.valor_tarifa }}</strong></small
                      >
                    </div>
                  </td>
                  <td>
                    <div v-if="tarifa.valor_km_adicional">
                      <small
                        >Km Adicional:
                        <strong>{{ tarifa.valor_km_adicional }}</strong></small
                      >
                    </div>
                    <div v-if="tarifa.valor_horas_extra">
                      <small
                        >Hora Extra:
                        <strong>{{ tarifa.valor_horas_extra }}</strong></small
                      >
                    </div>
                  </td>
                  <td class="text-center">{{ tarifa.fecha_ini }}</td>
                  <td class="text-center">{{ tarifa.fecha_fin }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TepTarifaNacional",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      tep_ruta: null,
      form: {
        id: null,
        tep_contrato_nacional_id: this.$parent.id,
        tep_ruta_id: null,
        fecha_ini: null,
        fecha_fin: null,
        tipo_vehiculo_id: null,
        tipo_tarifa: null,
        numero_tarifa: null,
        nombre_tarifa: null,
        km_min: null,
        val_km_destapados: null,
        val_km_pavimentados: null,
        valor_km_adicional: null,
        valor_tarifa: null,
        valor_horas_extra: null,
        rango_km_ini: null,
        rango_km_fin: null,
        max_horas: null,
        round_trip: false,
      },
      listasForms: {
        sitios: [],
        estados: {},
      },
      filtros: { n_tarifa: "", tipo_vh: "", tipo_tarifa: "", ruta: "" },
      datos_tarifa_historico: [],
      tarifa_historico: [],
    };
  },
  validations() {
    switch (this.form.tipo_tarifa) {
      case 1:
        return {
          form: {
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            tipo_vehiculo_id: {
              required,
            },
            tipo_tarifa: {
              required,
            },
            valor_horas_extra: {
              required,
            },
            numero_tarifa: {
              required,
            },
            nombre_tarifa: {
              required,
            },
            val_km_destapados: {
              required,
            },
            val_km_pavimentados: {
              required,
            },
            km_min: {
              required,
            },
          },
        };

      case 2:
        return {
          form: {
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            tipo_vehiculo_id: {
              required,
            },
            tipo_tarifa: {
              required,
            },
            numero_tarifa: {
              required,
            },
            nombre_tarifa: {
              required,
            },
            valor_tarifa: {
              required,
            },
            valor_horas_extra: {
              required,
            },
            rango_km_ini: {
              required,
            },
            rango_km_fin: {
              required,
            },
            valor_km_adicional: {
              required,
            },
          },
        };

      default:
        return {
          form: {
            fecha_ini: {
              required,
            },
            fecha_fin: {
              required,
            },
            tipo_vehiculo_id: {
              required,
            },
            tipo_tarifa: {
              required,
            },
            valor_horas_extra: {
              required,
            },
            numero_tarifa: {
              required,
            },
            nombre_tarifa: {
              required,
            },
          },
        };
    }
  },
  computed: {
    filteredRowsDetail() {
      if (this.$parent.form.tarifas) {
        return this.$parent.form.tarifas.filter((det) => {
          if (det.det_tarifas[0] !== undefined) {
            const n_tarifa = det.det_tarifas[0].nombre_tarifa.toLowerCase();
            const tipo_vh = det.tipo_vehiculo.nombre.toLowerCase();
            const tipo_tarifa = det.det_tarifas[0].nTipoTarifa.toLowerCase();
            const ruta = det.tep_ruta
              ? det.tep_ruta.nombre.toLowerCase()
              : null;
            /*const turno = det.tep_det_tarifa_interno.tarifa_interno.turno.nombre.toLowerCase(); */

            const searchTermNtarifa = this.filtros.n_tarifa.toLowerCase();
            const searchTermTipoVh = this.filtros.tipo_vh.toLowerCase();
            const searchTermTipoTarifa = this.filtros.tipo_tarifa.toLowerCase();
            const searchTermRuta = this.filtros.ruta.toLowerCase();
            /*const searchTermturno = this.buscarDet.turno.toLowerCase(); */

            return n_tarifa.includes(searchTermNtarifa) &&
              tipo_vh.includes(searchTermTipoVh) &&
              tipo_tarifa.includes(searchTermTipoTarifa) &&
              ruta !== null
              ? ruta.includes(searchTermRuta)
              : ruta == null;
          } else {
            return det;
          }
        });
      } else {
        return this.$parent.form.tarifas;
      }
    },
  },
  methods: {
    getDatosModal(tarifa = null) {
      this.$parent.cargando = true;
      this.resetForm();
      if (tarifa) {
        const det_tarifa = tarifa.det_tarifas[0];
        this.form = {
          id: tarifa.id,
          tep_contrato_nacional_id: tarifa.tep_contrato_nacional_id,
          tarifa: det_tarifa.tarifa,
          tep_ruta_id: tarifa.tep_ruta_id,
          fecha_ini: null,
          fecha_fin: null,
          tipo_vehiculo_id: tarifa.tipo_vehiculo_id,
          tipo_tarifa: det_tarifa.tipo_tarifa,
          numero_tarifa: det_tarifa.numero_tarifa,
          nombre_tarifa: det_tarifa.nombre_tarifa,
          km_min: det_tarifa.km_min,
          val_km_destapados: det_tarifa.val_km_destapados,
          val_km_pavimentados: det_tarifa.val_km_pavimentados,
          valor_km_adicional: det_tarifa.valor_km_adicional,
          valor_horas_extra: det_tarifa.valor_horas_extra,
          valor_tarifa: det_tarifa.valor_tarifa,
          rango_km_ini: det_tarifa.rango_km_ini,
          rango_km_fin: det_tarifa.rango_km_fin,
          max_horas: det_tarifa.max_horas,
          round_trip: det_tarifa.round_trip,
        };
        this.tep_ruta = {
          id: this.form.tep_ruta_id,
          nombre: tarifa.tep_ruta ? tarifa.tep_ruta.nombre : null,
        };
      }
      this.$parent.cargando = false;
    },

    getDatosHistorico(tarifa) {
      this.tarifa_historico = null;

      let nombre_ruta = "";
      if (tarifa.tep_ruta) {
        nombre_ruta = tarifa.tep_ruta.nombre;
      }

      this.datos_tarifa_historico = {
        ruta: nombre_ruta,
        fecha_ini: tarifa.det_tarifas[0].fecha_ini,
        fecha_fin: tarifa.det_tarifas[0].fecha_fin,
        tipo_vehiculo: tarifa.tipo_vehiculo.nombre,
        tipo_tarifa: tarifa.det_tarifas[0].tipo_tarifa,
        nombre_tipo_tarifa: tarifa.det_tarifas[0].nTipoTarifa,
        numero_tarifa: tarifa.det_tarifas[0].numero_tarifa,
        nombre_tarifa: tarifa.det_tarifas[0].nombre_tarifa,
        valor_tarifa: tarifa.det_tarifas[0].valor_tarifa,
        valor_horas_extra: tarifa.det_tarifas[0].valor_horas_extra,
        rango_km_ini: tarifa.det_tarifas[0].rango_km_ini,
        rango_km_fin: tarifa.det_tarifas[0].rango_km_fin,
        val_km_destapados: tarifa.det_tarifas[0].val_km_destapados,
        val_km_pavimentados: tarifa.det_tarifas[0].val_km_pavimentados,
        valor_km_adicional: tarifa.det_tarifas[0].valor_km_adicional,
        km_min: tarifa.det_tarifas[0].km_min,
      };
      this.tarifa_historico = tarifa.det_tarifas;
    },
    resetForm() {
      this.form = {
        id: null,
        tep_contrato_nacional_id: this.$parent.id,
        tep_ruta_id: null,
        fecha_ini: null,
        fecha_fin: null,
        tipo_vehiculo_id: null,
        tipo_tarifa: null,
        numero_tarifa: null,
        nombre_tarifa: null,
        km_min: null,
        val_km_destapados: null,
        val_km_pavimentados: null,
        valor_tarifa: null,
        rango_km_ini: null,
        rango_km_fin: null,
        max_horas: null,
        round_trip: false
      };
    },

    buscarRuta(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "/api/tep/Ruta/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.$parent.listasForms.rutas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    resetValues() {
      if (!this.form.id) {
        this.form.km_min = null;
        this.form.val_km_destapados = null;
        this.form.val_km_pavimentados = null;
        this.form.valor_tarifa = null;
        this.form.rango_km_ini = null;
        this.form.rango_km_fin = null;
      } else if (this.form.tipo_tarifa == 1) {
        this.form.valor_tarifa = null;
        this.form.rango_km_ini = null;
        this.form.rango_km_fin = null;
      } else if (this.form.tipo_tarifa == 2) {
        this.form.km_min = null;
        this.form.val_km_destapados = null;
        this.form.val_km_pavimentados = null;
      }
      this.nombreTarifa();
    },

    validarKm() {
      if (this.form.rango_km_ini && this.form.rango_km_fin) {
        if (this.form.rango_km_ini >= this.form.rango_km_fin) {
          this.form.rango_km_fin = null;
          this.$swal({
            icon: "error",
            title: `El rango de km inicial debe se menor que el rango de km final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      }
      this.nombreTarifa();
    },

    async validarFechas() {
      // Se valida que las fechas estén dentro de la vigencia del contrato
      await this.validaVigenciaContrato();
      // Se valida que la fecha inicial no sea mayor a la fecha final
      await this.validaFechasVigencia();
      // Si ya la tarifa ha sido creada se valida que la fecha inicial a crear so sea menor de la fecha final de la tarifa creada vigente
      if (this.form.id) {
        await this.validaFechasTarifa();
      }
    },

    validaVigenciaContrato() {
      const inicio_contrato = this.$parent.form.fecha_ini;
      const fin_contrato = this.$parent.form.fecha_fin;
      if (this.form.fecha_ini) {
        if (
          !this.validaFechas(inicio_contrato, this.form.fecha_ini) ||
          !this.validaFechas(this.form.fecha_ini, fin_contrato)
        ) {
          this.form.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_fin) {
        if (
          !this.validaFechas(inicio_contrato, this.form.fecha_fin) ||
          !this.validaFechas(this.form.fecha_fin, fin_contrato)
        ) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Fin de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechasVigencia() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        if (!this.validaFechas(this.form.fecha_ini, this.form.fecha_fin)) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa no puede ser mayor a la Fecha Fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechasTarifa() {
      if (this.form.fecha_ini) {
        const fecha_fin_det_tarifa = this.$parent.form.tarifas[0].det_tarifas[0]
          .fecha_fin;
        if (!this.validaFechas(fecha_fin_det_tarifa, this.form.fecha_ini)) {
          this.form.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa se cruza con la vigencia de la tarifa actual, se debe poner una fecha posterior a ${fecha_fin_det_tarifa}...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechas(fecha_menor, fecha_mayor) {
      fecha_menor = new Date(fecha_menor);
      fecha_mayor = new Date(fecha_mayor);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        return false;
      }
      return true;
    },

    nombreTarifa() {
      this.form.nombre_tarifa = null;

      // Se busca el nombre de la ruta
      let nombre_1 = "";
      if (this.form.tep_ruta_id) {
        const ruta = this.$parent.listasForms.rutas.find(
          (ruta) => ruta.id == this.form.tep_ruta_id
        );
        nombre_1 = ruta.nombre;
      }

      // Se busca el tipo de tarifa
      let nombre_2 = "";
      if (this.form.tipo_tarifa) {
        const tipo_tarifa = this.$parent.listasForms.tipos_tarifas.find(
          (tipo_tarifa) => tipo_tarifa.numeracion == this.form.tipo_tarifa
        );

        // Se complementa el nombre de la tarifa
        if (this.form.tipo_tarifa === 2) {
          if (this.form.rango_km_ini && this.form.rango_km_fin) {
            nombre_2 = `${tipo_tarifa.descripcion} (${this.form.rango_km_ini} - ${this.form.rango_km_fin})`;
          }
        } else {
          nombre_2 = tipo_tarifa.descripcion;
        }
      }

      if (nombre_2) {
        this.form.nombre_tarifa = `${nombre_1} ${nombre_2}`;
      }
    },

    selectRuta() {
      this.form.tep_ruta_id = null;
      if (this.tep_ruta) {
        this.form.tep_ruta_id = this.tep_ruta.id;
      }
    },

    save() {
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/tep/contratosNacionales/tarifas",
          data: this.form,
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.$parent.form.tarifas = response.data.tarifas;
            this.$refs.closeModal.click();

            this.$swal({
              icon: "success",
              title: "La tarifa se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.resetForm();
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(tarifa) {
      this.$swal({
        title: "Está seguro de eliminar esta Tarifa?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .delete("/api/tep/contratosNacionales/tarifas/" + tarifa.id)
            .then((response) => {
              this.$parent.cargando = false;
              this.$parent.form.tarifas = response.data.tarifas;
              this.$swal({
                icon: "success",
                title: "Se eliminó la Tarifa exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
};
</script>
